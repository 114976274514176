import React from 'react';
import { useState, useEffect } from 'react';
import styles from './styles/team.module.scss';
import json_team_en from './jsons/en/team_en.json';
import json_team_pt from './jsons/pt/team_pt.json';

const Team = () => {

    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_team_en : json_team_pt;

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
        })
    });

    const handle_click = (url) => {

        window.open(url, "__blank");
    }

    return (
        <div className = {styles.root_div}>

            <div 
                className = {styles.root_div_background}
                style = {{
                    backgroundImage: `url(${require(`${json_data.background_image}`)})`,
                }}/>

            <div className = {styles.root_inner_div}>

                <div className = {styles.suptitle_div}>
                    {json_data.suptitle}
                </div>

                <div 
                    className = {styles.title_div}
                    dangerouslySetInnerHTML = {{__html: json_data.title}}/>

                <div className = {styles.items_div}>
                    {
                        json_data.items.map((item, index) => (
                            <div
                                key = {index} 
                                className = {styles.item_div}>
                                <div className = {styles.item_inner_div}>

                                    <div 
                                        className = {styles.item_background}
                                        style = {{
                                            backgroundImage: `url(${require(`${json_data.items_background_image}`)})`,
                                        }}/>

                                    <div 
                                        className = {styles.image_div}
                                        style = {{
                                            backgroundImage: `url(${require(`${item.image}`)})`,
                                        }}/>

                                    <div
                                        onClick = {() => handle_click(item.linkedin_url)}
                                        className = {styles.linkedin_div}>
                                        <div 
                                            className = {styles.linkedin_image}
                                            style = {{
                                                backgroundImage: `url(${require(`${json_data.linkedin_icon}`)})`,
                                            }}/>
                                    </div>

                                    <div className = {styles.name_div}>
                                        {item.name}
                                    </div>

                                    <div className = {styles.role_div}>
                                        {item.role}
                                    </div>
                                </div>

                                {(json_data.items.indexOf(item) !== (json_data.items.length - 1)) ? 
                                <div className = {styles.item_separator} />
                                : <></>}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Team;