import React from 'react';
import { useState, useEffect } from 'react';
import { isChrome } from "react-device-detect";
import styles from './styles/services_modal.module.scss';
import json_services_modal_en from './jsons/en/services_modal_en.json';
import json_services_modal_pt from './jsons/pt/services_modal_pt.json';

const ServicesModal = ( props ) => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_services_modal_en : json_services_modal_pt;
    const [background_opacity, set_background_opacity] = useState(0.25);
    const [at_the_top, set_at_the_top] = useState(false);

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // listen for navbar changes
        function at_the_top() {
            set_background_opacity(0.25);
            set_at_the_top(true);
        }
        window.addEventListener('at_the_top', at_the_top);

        // listen for navbar changes
        function not_at_the_top() {
            set_background_opacity(0.65);
            set_at_the_top(false);
        }
        window.addEventListener('not_at_the_top', not_at_the_top);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
            window.removeEventListener('at_the_top', at_the_top);
            window.removeEventListener('not_at_the_top', not_at_the_top);
        })
    });

    return (
        <div 
            className = {styles.root_div}
            style = {{
                backgroundColor: "rgba(255, 255, 255, " + (isChrome && !at_the_top ? 1.0 : background_opacity) + ")",
            }}>
            {json_data.items.map((item, index) => (
                <div
                    key = {index} 
                    className = {styles.item_div}>
                    {item}
                </div>
            ))}
        </div>
    );
}

export default ServicesModal;