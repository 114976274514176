import React, { useState } from "react";
import AnyAttribute from 'react-any-attr';
import styles from './styles/scroll_up_button.module.scss';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollUpButton = () => {

    //////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////
    const [at_the_top, set_at_the_top] = useState(true);
    const [show_bounce_animation, set_show_bounce_animation] = useState("0");

    // listen for scroll changes
    window.addEventListener('at_the_top', () => {
        set_at_the_top(true);
    });

    window.addEventListener('not_at_the_top', () => {
        set_at_the_top(false);
    });

    const handle_click = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
        set_show_bounce_animation("1");
    }

    return (
        <div>
            <AnyAttribute
                attributes = {{
                    show_bounce_animation: show_bounce_animation,
                }}>
                <div 
                    className = {styles.button_container}
                    onClick = {() => handle_click()}
                    onAnimationEnd = {() => set_show_bounce_animation("0")}
                    style = {{
                        opacity: (at_the_top ? "0.0" : "1.0"),
                    }}>
                    <div className = {styles.arrow_div}>
                        <ArrowUpwardIcon color = {"#394A6A"} />
                    </div>
                </div>
            </AnyAttribute>
        </div>
    );
}

export default ScrollUpButton;