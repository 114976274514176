import React from 'react';
import { send } from 'emailjs-com';
import AnyAttribute from 'react-any-attr';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import styles from './styles/footer.module.scss';
import json_footer_en from './jsons/en/footer_en.json';
import json_footer_pt from './jsons/pt/footer_pt.json';
import CircularProgress from '@material-ui/core/CircularProgress';

const Footer = () => {

    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_footer_en : json_footer_pt;
    const [status_message, set_status_message] = useState("");
    const [is_loading, set_is_loading] = useState(false);
    const [window_width, set_window_width] = useState(window.innerWidth);
    const [show_bounce_animation, set_show_bounce_animation] = useState("0");

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));

            let new_json_data = localStorage.getItem("language") === "EN" ? json_footer_en : json_footer_pt;
        
            if(name_helper_text !== "")
                set_name_helper_text(new_json_data.third_part.form_name_error);

            if(email_helper_text !== "")
                set_email_helper_text(new_json_data.third_part.form_email_error);

            if(message_helper_text !== "")
                set_message_helper_text(new_json_data.third_part.form_message_error);
        }
        window.addEventListener('language_change', language_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
            window.removeEventListener('resize', resize);
        })
    });

    // name textfield states
    const [name, set_name] = useState("");
    const [name_helper_text, set_name_helper_text] = useState("");

    // email textfield states
    const [email, set_email] = useState("");
    const [email_helper_text, set_email_helper_text] = useState("");

    // message textfield states
    const [message, set_message] = useState("");
    const [message_helper_text, set_message_helper_text] = useState("");

    const base_input_props_style = { 
        color: "var(--primary_colour)",
        backgroundColor: "rgba(200, 200, 200, 0.15)",
        borderRadius: "8px",
        fontSize: 13.5,
        border: "",
    };

    const base_input_label_style = { 
        color: "var(--primary_colour)",
        fontSize: 13.5,
        backgroundColor: "",
        fontFamily: "light",
        paddingLeft: "",
        paddingRight: "",
    };

    const handle_form_click = () => {

        set_show_bounce_animation("1");

        if(name === "")
            set_name_helper_text(json_data.form_name_error);
        else
            set_name_helper_text("");
        
        if(email === "")
            set_email_helper_text(json_data.form_email_error);
        else
            set_email_helper_text("");

        if(message === "")
            set_message_helper_text(json_data.form_message_error);
        else
            set_message_helper_text("");

        if(name !== "" && email !== "" && message !== ""){
        
            set_is_loading(true);

            const stuff_to_send = {
                "name": name,
                "email": email,
                "message": message,
            }
            
            send(
                'service_cps1lbc',
                'template_l4u59fk',
                stuff_to_send,
                'jvDhGEjvVDAzQhpD7'
            ).then((response) => {
                set_is_loading(false);
                console.log('SUCCESS!', response.status, response.text);

                set_status_message("success");

                setTimeout(() => {
                    set_status_message("");
                }, 3000);
            })
            .catch((err) => {
                set_is_loading(false);
                console.log('FAILED...', err);

                set_status_message("error");

                setTimeout(() => {
                    set_status_message("");
                }, 3000);
            });
        }
    }

    const handle_click = (url) => {

        window.open(url, "__blank");
    }

    return (
        <div className = {styles.root_div}>

            <div className = {styles.root_div_top_bar} />

            <div className = {styles.root_inner_div}>
                
                <div className = {styles.left_part_super_div}>
                    <div className = {styles.first_part_div}>
                        
                        <div className = {styles.logo_and_legal_text_div}>
                            <div 
                                className = {styles.logo_div}
                                style = {{
                                    backgroundImage: `url(${require(`${json_data.first_part.logo}`)})`
                                }}/>

                            <div className = {styles.legal_text_div}>
                                {json_data.first_part.legal_text.replace("YEAR", new Date().getFullYear().toString())}
                            </div>
                        </div>

                        <div
                            onClick = {() => handle_click(json_data.first_part.health_tech_portugal_link.url)}
                            className = {styles.health_tech_portugal_link_div}>
                            
                            <div 
                                className = {styles.health_tech_portugal_logo_div}
                                style = {{
                                    backgroundImage: `url(${require(`${json_data.first_part.health_tech_portugal_link.image}`)})`
                                }}/>

                            <div className = {styles.health_tech_portugal_separator_div} />

                            <div 
                                className = {styles.health_tech_portugal_text_div}
                                dangerouslySetInnerHTML = {{__html: json_data.first_part.health_tech_portugal_link.text}} />

                        </div>
                        
                    </div>

                    <div className = {styles.second_part_div}>
                        {
                            json_data.second_part.map((item, index) => (
                                index !== (json_data.second_part.length - 1) ?
                                <div
                                    key = {index} 
                                    className = {styles.second_part_item_div}>
                                    <div 
                                        className = {styles.second_part_item_title_div} 
                                        dangerouslySetInnerHTML = {{__html: item.title}}/>

                                    <div 
                                        className = {styles.second_part_item_description_div} 
                                        dangerouslySetInnerHTML = {{__html: item.description}}/>
                                </div>
                                :
                                <div
                                    key = {index}  
                                    className = {styles.socials_div}>
                                    {
                                        item.socials_items.map((socials_item, socials_item_index) => (
                                            <div
                                                key = {socials_item_index} 
                                                onClick = {() => handle_click(socials_item.url)}
                                                className = {styles.social_item_div}>
                                                <div 
                                                    className = {styles.social_item_icon}
                                                    style = {{
                                                        backgroundImage: `url(${require(`${socials_item.icon}`)})`,
                                                    }}/>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className = {styles.third_part_div}>
                    <div className = {styles.contact_us_inner_div}>
                        <div 
                            className = {styles.contact_us_form_div}
                            style = {{
                                minWidth: (window_width > 900 ? "450px" : (window_width > 780 ? "350px" : (window_width > 500 ? "335px" : "280px"))),
                            }}>
                            <div className = {styles.contact_us_title}>
                                {json_data.third_part.title}
                            </div>
                            
                            <div 
                                className = {styles.text_field_div}
                                style = {{
                                    marginTop: "30px",
                                }}>
                                <TextField 
                                    onChange = {(e) => set_name(e.target.value)}
                                    className = {styles.text_field} 
                                    id = "outlined-basic" 
                                    error = {name_helper_text !== "" ? true : false}
                                    InputProps={{ style: base_input_props_style }}
                                    InputLabelProps={{ style: base_input_label_style }}
                                    helperText = {name_helper_text}
                                    label = {json_data.third_part.form_name_hint} 
                                    variant = "outlined" 
                                    type = "text" />
                            </div>
                            <div className = {styles.text_field_div}>
                                <TextField 
                                    onChange = {(e) => set_email(e.target.value)}
                                    className = {styles.text_field} 
                                    id = "outlined-basic" 
                                    error = {email_helper_text !== "" ? true : false}
                                    InputProps={{ style: base_input_props_style }}
                                    InputLabelProps={{ style: base_input_label_style }}
                                    helperText = {email_helper_text}
                                    label = {json_data.third_part.form_email_hint} 
                                    variant = "outlined" 
                                    type = "text" />
                            </div>
                            <div className = {styles.text_field_div}>
                                <TextField 
                                    onChange = {(e) => set_message(e.target.value)}
                                    className = {styles.text_field} 
                                    id = "outlined-basic" 
                                    multiline
                                    rows = {4}
                                    error = {message_helper_text !== "" ? true : false}
                                    InputProps={{ style: base_input_props_style }}
                                    InputLabelProps={{ style: base_input_label_style }}
                                    helperText = {message_helper_text}
                                    label = {json_data.third_part.form_message_hint} 
                                    variant = "outlined" 
                                    type = "text" />
                            </div>
                            <AnyAttribute
                                attributes = {{
                                    show_bounce_animation: show_bounce_animation,
                                }}>
                                <div 
                                    className = {styles.contact_us_button}
                                    onClick = {() => handle_form_click()}
                                    onAnimationEnd = {() => set_show_bounce_animation("0")}>
                                    <div 
                                        className = {styles.contact_us_button_text}
                                        style = {{
                                            opacity: (is_loading || status_message !== "" ? "0.0" : "1.0"),
                                        }}>
                                        {json_data.third_part.form_button_text}
                                    </div>
                                    <div className = {styles.contact_us_button_icon_div}>
                                        <CircularProgress 
                                            size = {20}
                                            style = {{
                                                opacity: (is_loading ? "1.0" : "0.0"),
                                                color: "white",
                                            }} />

                                        <div 
                                            className = {styles.status_icon_div}
                                            style = {{
                                                visibility: (status_message === "" ? "hidden" : "visible"),
                                                backgroundImage: `url(${require(`${status_message === "error" ? json_data.third_part.form_on_error_icon_url : json_data.third_part.form_on_success_icon_url}`)})`,
                                            }}/>
                                    </div>
                                </div>
                            </AnyAttribute>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;