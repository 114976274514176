import Team from "./Team";
import Cover from './Cover';
import Footer from "./Footer";
import Company from "./Company";
import Services from "./Services";
import React, { useEffect } from 'react';
import styles from './styles/home.module.scss';

const Home = () => {

    // -----------------------------
    // scroll to the top of the page
    // -----------------------------
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return ( 
        <div className = {styles.desktop_root}>
            
            <Cover />

            <div id = "company">
                <Company />
            </div>

            <div id = "services">
                <Services />
            </div>

            <div id = "team">
                <Team />
            </div>

            <div id = "contact_us">
                <Footer />
            </div>
            
        </div>
    );
}

export default Home;