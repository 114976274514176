import React from 'react';
import { useState, useEffect } from 'react';
import { Parallax } from "react-scroll-parallax";
import styles from './styles/services.module.scss';
import json_services_en from './jsons/en/services_en.json';
import json_services_pt from './jsons/pt/services_pt.json';
import video_en from "./resources/videos/diagram_en.m4v";
import video_pt from "./resources/videos/diagram_pt.m4v";

const Services = () => {

    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_services_en : json_services_pt;

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
        })
    });

    return (
        <div className = {styles.root_div}>

            <div className = {styles.root_inner_div}>

                <div className = {styles.main_title_super_div}>
                    <div 
                        className = {styles.main_title}
                        dangerouslySetInnerHTML = {{__html: json_data.title}}/>

                    <div 
                        className = {styles.main_title_background}
                        style = {{
                            backgroundImage: `url(${require(`${json_data.main_title_background}`)})`
                        }}/>
                </div>

                <Parallax translateY = {[-5, 5]}>
                    <video className = {styles.video_div} autoPlay loop muted id = "video">
                        <source src = {language === "EN" ? video_en : video_pt} type = "video/mp4" />
                    </video>
                </Parallax>

                <div className = {styles.services_div}>
                    {json_data.items.map((item, index) => (
                        <div 
                            key = {index}
                            className = {styles.service_super_div}>
                            <div className = {styles.service_text_div}>
                                <div className = {styles.service_text_inner_div}>
                                    <div 
                                        className = {styles.suptitle}>
                                        {item.suptitle}
                                    </div>
                                    <div 
                                        className = {styles.title}>
                                        {item.title}
                                    </div>
                                    <div className = {styles.description_items}>
                                        {
                                            item.description_items.map((description_item, index) => (
                                                <div
                                                    key = {index} 
                                                    className = {styles.description_item}>
                                                    <div className = {styles.description_item_bullet_symbol}>
                                                        {json_data.bullet_symbol}
                                                    </div>
                                                    <div
                                                        key = {index} 
                                                        className = {styles.description_item_text}
                                                        dangerouslySetInnerHTML = {{__html: description_item}} />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
}

export default Services;