import React from "react";
import ReactDOM from 'react-dom';
import ProgressBar from "./ProgressBar";
import AnyAttribute from 'react-any-attr';
import ServicesModal from "./ServicesModal";
import useMeasure from "react-use-measure";
import { useHistory } from "react-router-dom";
import styles from "./styles/navbar.module.scss";
import LanguageSelector from "./LanguageSelector";
import { useEffect, useState, useRef } from "react";
import json_navbar_en from './jsons/en/navbar_en.json';
import json_navbar_pt from './jsons/pt/navbar_pt.json';
import { isFirefox, isSafari, isChrome } from "react-device-detect";

const Navbar = () => {

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const navbar_height = parseInt(getComputedStyle(document.body).getPropertyValue('--navbar_height').split("px")[0]);
    const json_data = language === "EN" ? json_navbar_en : json_navbar_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);
    const max_width = parseInt(getComputedStyle(document.body).getPropertyValue('--max_width').split("px")[0]);
    const [hovered_middle_item, set_hovered_middle_item] = useState("");
    const [services_word_position, set_services_word_position] = useState({
        "left": -1,
        "top": -1,
    });
    const [show_services_word_modal, set_show_services_word_modal] = useState(false);
    const [mouse_is_on_services_modal, set_mouse_is_on_services_modal] = useState(false);
    const services_word_position_ref = useRef(null);

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('resize', resize);
            window.removeEventListener('language_change', language_change);
        })
    });

    // styles
    const navbar_background_color = ((!CSS.supports("-webkit-backdrop-filter", "blur(5px)") && !CSS.supports("backdrop-filter", "blur(5px)")) ? "rgba(255, 255, 255, 1.0)" : "rgba(255, 255, 255, 0.65)");
    const navbar_transition = isChrome ? "all 0.1s ease" : "all 0.1s ease"
    const navbar_box_shadow = "rgba(100, 100, 111, 0.2) 0px 10px 29px 0px";
    const [red_middle_item, set_red_middle_item] = useState("");
    const [navbar_style, set_navbar_style] = useState({transition: navbar_transition, borderBottom: "1px solid rgba(150, 150, 150, 1.0)", width: "100%"});
    const [tokenModalRef, tokenModalDimensions] = useMeasure();
    const [show_bounce_animation, set_show_bounce_animation] = useState("0");

    const shown_hamburguer_menu_style = {
        width: "235px",
        height: "100vh",
        display: "flex",
        transform: "translateX(0px)",
        transition: "all 0.25s ease-in-out",
    };

    const hidden_hamburguer_menu_style = {
        width: "235px",
        height: "100vh",
        display: "flex",
        transform: "translateX(-235px)",
        transition: "all 0.25s ease-in-out",
    };
    
    const [hamburguer_menu_style, set_hamburguer_menu_style] = useState(hidden_hamburguer_menu_style);

    const normal_navbar_style = {
        transition: navbar_transition, 
        borderBottom: "1px solid rgba(150, 150, 150, 1.0)", 
        width: "100%", 
        boxShadow: navbar_box_shadow,
        backgroundColor: navbar_background_color,
    }

    const on_top_navbar_style = {
        transition: navbar_transition, 
        border: "none", 
        width: "100%", 
        boxShadow: "none",
        backdropFilter: "blur(0px)",
    }
    
    // auxiliary variables
    let history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    
    // item arrays
    const middle_navbar_items = json_data.navbar_items.slice(1, json_data.navbar_items.length - 2);

    // variables to determine the current url
    let current_url = (window.location.href[window.location.href.length - 1] === "/") ? (window.location.href.split("#")[0]) : (window.location.href.split("#")[0] + "/");
    current_url = current_url.replace("http://", "").replace("https://", "");
    let is_home = current_url.split("/").length <= 2;

    // scrolling direction
    const [current_scroll_position, set_current_scroll_position] = useState(localStorage.getItem("current_scroll_position") !== null ? parseInt(localStorage.getItem("current_scroll_position")) : 0);
    const [initial_status, set_initial_status] = useState(true);
    const [navbar_status, set_navbar_status] = useState("expanded");
    const [scrollDir, setScrollDir] = useState("scrolling up");
    const [scroll_amount, set_scroll_amount] = useState(0); // used to make sure that the navbar starts with the proper style
    
    const [scroll_position, set_scroll_position] = useState(localStorage.getItem('scroll_position')); // used to make sure that the navbar starts with the proper style
    if(scroll_position === null){ 
        set_scroll_position(0);
        localStorage.setItem('scroll_position', 0); 
        forceUpdate();
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // AUXILIARY FUNCTIONS
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // initial setup for the navbar
    // -----------------------------------------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {

        if(isFirefox && initial_status){
            window.scrollTo(0, scroll_position);
        }

        set_current_scroll_position(window.pageYOffset);
        localStorage.setItem("current_scroll_position", window.pageYOffset);
        window.dispatchEvent(new Event("current_scroll_position_change"));

        // determine which (if any) navbar middle element should be coloured red
        let red_middle_item_aux = "";
        var en_middle_navbar_items = json_navbar_en.navbar_items.slice(1, json_navbar_en.navbar_items.length - 2)
        
        for(let i = 0; i < en_middle_navbar_items.length; i++){

            let i_aux = en_middle_navbar_items[i][0].toLowerCase().replace(" & ", "_and_").replaceAll(" ", "_")

            var current_y_position = window.pageYOffset;

            var target_top = parseInt(document.getElementById(i_aux).getBoundingClientRect().top);
            
            if(current_y_position > (current_y_position + target_top - navbar_height - 30))
                red_middle_item_aux = middle_navbar_items[i][0];
        }

        // disable the red text for the remainder of the page (i.e., we've scrolled past the last middle navbar element)
        if(current_y_position > (current_y_position + parseInt(document.getElementById(en_middle_navbar_items[en_middle_navbar_items.length - 1][0].toLowerCase().replace(" & ", "_and_").replaceAll(" ", "_")).getBoundingClientRect().bottom) - navbar_height - 30))
            red_middle_item_aux = ""
        
        set_red_middle_item(red_middle_item_aux);

    // eslint-disable-next-line
    }, [scroll_position]);
    
    useEffect(() => {

        set_navbar_status("expanded");

        let current_url = (window.location.href[window.location.href.length - 1] === "/") ? (window.location.href.split("#")[0]) : (window.location.href.split("#")[0] + "/");
        current_url = current_url.replace("http://", "").replace("https://", "");

        if(current_url.split("/").length <= 2){
            if(window.pageYOffset < 1){
                set_navbar_style(on_top_navbar_style);
            }
            else{
                set_navbar_style(normal_navbar_style);
                forceUpdate();
            }
        }
        
        else
            set_navbar_style(normal_navbar_style);

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const unlisten = history.listen((location) => {

            set_navbar_status("expanded");

            // home page
            if(window.pageYOffset !== 0)
                if(location["pathname"] ===  "/") { 
                    set_navbar_style({transition: navbar_transition, backgroundColor: "rgba(255, 255, 255, 1.0)", border: "none", boxShadow: navbar_box_shadow}); forceUpdate(); 
                }
            
        });
        return function cleanup() { 
            unlisten()
        }
    // eslint-disable-next-line
    }, [history]);

    useEffect(() => {

        if(tokenModalDimensions !== null && tokenModalDimensions !== undefined){

            let new_services_word_position = {
                "left": -1,
                "top": -1,
            }

            let tokenIconMiddlePoint = ReactDOM.findDOMNode(services_word_position_ref.current).getBoundingClientRect().x + (ReactDOM.findDOMNode(services_word_position_ref.current).getBoundingClientRect().width / 2);
            new_services_word_position["left"] = (tokenIconMiddlePoint - (tokenModalDimensions["width"] / 2));
            new_services_word_position["top"] = ReactDOM.findDOMNode(services_word_position_ref.current).getBoundingClientRect().height + 10 ;

            set_services_word_position(new_services_word_position);
        }

    }, [window_width, tokenModalDimensions]);

    // ---------------------------------------------------------------
    // redirect the user to different pages based on its input
    // ---------------------------------------------------------------
    const handle_click = (id) => {

        is_home = false;

        if(id === "home"){
            is_home = true;
            
            window.scrollTo(0, 0);
            history.push("/");

            localStorage.setItem("current_scroll_position", "0");
        }

        else if(id.includes("http")){
            window.open(id, "_blank");
        }
        
        else{
            
            if(id === "CONTACT US")
                set_show_bounce_animation("1");

            id = id.toLowerCase().replace(" & ", "_and_").replaceAll(" ", "_")

            set_navbar_status("expanded");
            set_navbar_style(normal_navbar_style);
            forceUpdate();

            let target_top = parseInt(document.getElementById(id).getBoundingClientRect().top);
            if(target_top === 0)
                return

            let new_scroll_position = current_scroll_position + target_top - navbar_height - 20;
            window.scroll({top: new_scroll_position, left: 0, behavior: 'smooth'});
            
            localStorage.setItem("current_scroll_position", new_scroll_position);
            set_current_scroll_position(new_scroll_position);
        }

        hide_mobile_menu();
    }

    // ----------------------------------------------------------------------------------
    // determine the current scrolling direction
    // ----------------------------------------------------------------------------------
    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        const updateScrollDir = () => {

            let scroll_y_aux = window.pageYOffset;
            set_scroll_position(scroll_y_aux);
            forceUpdate();
            
            if (Math.abs(scroll_y_aux - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir((scroll_y_aux > lastScrollY && (scroll_amount > 2)) ? "scrolling down" : "scrolling up");
            lastScrollY = scroll_y_aux > 0 ? scroll_y_aux : 0;
            ticking = false;
            if(scroll_y_aux <= 5)
                setScrollDir("top");
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    
        // eslint-disable-next-line
    }, [scrollDir, scroll_position, forceUpdate]);

    useEffect(() => {
        if(navbar_style["backgroundColor"] !== navbar_background_color)
            window.dispatchEvent(new Event("at_the_top"));
        else
            window.dispatchEvent(new Event("not_at_the_top"));
    }, [navbar_style, navbar_background_color]);

    // listen for scroll changes
    window.addEventListener('at_the_top', () => {
        set_current_scroll_position(0);
        localStorage.setItem("current_scroll_position", "0");
    });

    // ------------------------------------------------------------------------------------------------------------------------------------
    // update the navbar's state according to the current scrolling direction
    // ------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        window.onscroll = () => {

            set_scroll_amount(scroll_amount + 1);
            set_initial_status(false);
            
            if(window.pageYOffset < 1){

                if(navbar_style !== on_top_navbar_style){
                    set_navbar_style(on_top_navbar_style);
                    set_navbar_status("expanded");
                    localStorage.setItem("navbar_status", "expanded");
                }
                

                if(is_home && scroll_amount >= 2)
                    set_navbar_style(on_top_navbar_style);
            }

            else{
                if(scroll_amount === 0){
                    set_navbar_status("expanded");
                    set_navbar_style(normal_navbar_style);
                    return;
                }
                
                if(scrollDir === "scrolling down"){ // the user is scrolling down

                    if((scroll_amount < 2))
                        return;

                    if((!isSafari) && initial_status){
                        set_initial_status(false);
                        return;
                    }

                    if(navbar_status === "expanded"){
                        set_navbar_style(normal_navbar_style);
                        localStorage.setItem("navbar_status", "collapsed");
                        set_navbar_status("collapsed");
                    }

                    //console.log("going down");
                }

                else{ // the user is scrolling up

                    if(navbar_status === "collapsed"){
                        set_navbar_style(normal_navbar_style);
                        localStorage.setItem("navbar_status", "expanded");
                        set_navbar_status("expanded");
                    }

                    //console.log("going up");
                }
            }
        }
        // eslint-disable-next-line
    }, [scrollDir, initial_status, scroll_amount]);

    const show_mobile_menu = () => {
        set_hamburguer_menu_style(shown_hamburguer_menu_style);
        document.body.style.overflow = "hidden";
    }

    const hide_mobile_menu = () => {
        
        if(hamburguer_menu_style["transform"] === "translateX(0px)"){
            set_hamburguer_menu_style(hidden_hamburguer_menu_style);
            document.body.style.overflow = "visible";
        }
    }

    const handle_mouse_enter_on_middle_div_item = (item) => {
        set_hovered_middle_item(item)

        if(item === "SERVICES" || item === "SERVIÇOS")
            set_show_services_word_modal(true)
    }

    const handle_mouse_leave_on_middle_div_item = (item) => {
        set_hovered_middle_item("")
    }

    useEffect(() => {

        if(!mouse_is_on_services_modal && hovered_middle_item !== "SERVICES" && hovered_middle_item !== "SERVIÇOS")
            set_show_services_word_modal(false)

    }, [mouse_is_on_services_modal, hovered_middle_item])

    return( 
        <div className = {navbar_style["backgroundColor"] === navbar_background_color ? styles.desktop_root : styles.desktop_root_on_top}>
            <ProgressBar z_index = {hamburguer_menu_style["transform"] === "translateX(0px)" ? 10 : 20}/>
            <div className = {styles.desktop_sub_root} style = {navbar_style}>
                
                    <div className = {styles.hamburguer_menu_div_left} style = {hamburguer_menu_style}>
                        
                        <div className = {styles.home_link_div}>
                            <div className = {styles.home_div} style = {{height: "100%", margin: "0 auto"}}>
                                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                    <div 
                                        onClick = {() => handle_click("home")}
                                        className = {styles.logo} 
                                        style = {{
                                            width: "175px",
                                            backgroundImage: `url(${require(`${json_data.navbar_items[0][0]}`)})`, 
                                            backgroundPosition: "center", 
                                            backgroundRepeat: "no-repeat", 
                                            backgroundSize: "contain"
                                        }}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className = {styles.hamburguer_menu_div_left_inner}>
                            {middle_navbar_items.map((navbar_item) => (
                                <div 
                                    key = {middle_navbar_items.indexOf(navbar_item)}
                                    className = {styles.hamburguer_menu_item_div}
                                    onClick = {() => handle_click(json_navbar_en.navbar_items[json_data.navbar_items.indexOf(navbar_item)][0])}>
                                        <div 
                                            className = {styles.hamburguer_menu_item_text}
                                            style = {{
                                                color: (red_middle_item === navbar_item[0] ? "var(--primary_colour)" : "rgba(57, 74, 106, 0.5)"),
                                                fontFamily: (red_middle_item === navbar_item[0] ? "semibold" : "regular"),
                                                opacity: (red_middle_item === navbar_item[0] ? "1.0" : "0.65"),
                                            }}>{navbar_item[0]}</div>
                                </div>
                            ))}
                        </div>
                        <div className = {styles.hamburguer_menu_bottom_div}>
                            <div className = {styles.hamburguer_menu_bottom_div_language_selector}>
                                <LanguageSelector navbar_or_hamburguer_menu = {"hamburguer_menu"} />
                            </div>
                            <div className = {styles.hamburguer_menu_div_left_background} style = {{backgroundImage: `url(${require(`${json_data.hamburguer_menu_background_image}`)})`}} />
                        </div>
                    </div>

                    <div 
                        className = {styles.hamburguer_menu_blur_div}
                        style = {{
                            visibility: (hamburguer_menu_style["transform"] === "translateX(0px)" ? "visible" : "hidden"),
                            opacity: (hamburguer_menu_style["transform"] === "translateX(0px)" ? "1.0" : "0.0")
                        }}
                        onClick = {() => hide_mobile_menu()}/>
                
                <div 
                    className = {styles.desktop_inner}
                    style = {{
                        width: (window_width <= (max_width + 100) ? (window_width <= 835 ? "100%" : "90%") : "100%"),
                    }}>

                    <div className = {styles.hamburguer_menu_icon_div}>
                        <div 
                            onClick = {() => show_mobile_menu()}
                            className = {styles.hamburguer_menu_icon} 
                            style = {{backgroundImage: `url(${require(`${json_data.hamburguer_menu_icon}`)})`}}
                        />
                    </div>
                    
                    {/* home button */}
                    <div 
                        className = {styles.home_div}
                        style = {{
                            width: (window_width < 400 ? "100px" : "220px")
                        }}>
                        <div className = {styles.vertical_align} style = {{height: "100%"}}>
                            <div 
                                onClick = {() => handle_click("home")}
                                className = {styles.logo} 
                                style = {{
                                    backgroundImage: `url(${require(`${json_data.navbar_items[0][0]}`)})`, 
                                    backgroundPosition: "center", 
                                    backgroundRepeat: "no-repeat", 
                                    backgroundSize: "contain",
                                    width: (window_width < 650 ? (window_width < 400 ? "160px" : "185px") : "220px")
                                }}
                            />
                        </div>
                    </div>

                    <div className = {styles.middle_div}>
                        {middle_navbar_items.map((navbar_item) => (
                            <div 
                                ref = {navbar_item[0] === "SERVICES" || navbar_item[0] === "SERVIÇOS" ? services_word_position_ref : null}
                                key = {middle_navbar_items.indexOf(navbar_item)}
                                onMouseEnter = {() => handle_mouse_enter_on_middle_div_item(navbar_item[0])}
                                onMouseLeave = {() => handle_mouse_leave_on_middle_div_item(navbar_item[0])}
                                className = {styles.middle_div_item}
                                style = {{
                                    color: ((red_middle_item === navbar_item[0] || hovered_middle_item === navbar_item[0] || ((navbar_item[0] === "SERVICES" || navbar_item[0] === "SERVIÇOS") && show_services_word_modal)) ? "var(--primary_colour)" : "rgba(57, 74, 106, 0.5)"),
                                    //transform: ((red_middle_item === navbar_item[0] || hovered_middle_item === navbar_item[0]) ? "scale(1.0)" : "scale(0.9)"),
                                    //fontFamily: ((red_middle_item === navbar_item[0] || hovered_middle_item === navbar_item[0]) ? "semibold" : "regular")
                                }}
                                onClick = {() => handle_click(json_navbar_en.navbar_items[json_data.navbar_items.indexOf(navbar_item)][0])}>
                                <div>{navbar_item[0]}</div>
                            </div>
                        ))}
                    </div>

                    {/* button */}
                    <div 
                        className = {styles.button_div}
                        style = {{
                            position: window_width < 400 ? "absolute" : "relative",
                            right: window_width < 400 ? "0" : "",
                        }}>
                        
                        <div className = {styles.button_inner_div}>
                            <div className = {styles.vertical_align}>
                                <AnyAttribute
                                    attributes = {{
                                        show_bounce_animation: show_bounce_animation,
                                    }}>
                                    <div 
                                        onClick = {() => handle_click(json_navbar_en.navbar_items[json_data.navbar_items.length - 2][0])} 
                                        className = {styles.button_button}
                                        onAnimationEnd = {() => set_show_bounce_animation("0")}>
                                        <p className = {styles.button_text}>
                                            {json_data.navbar_items[json_data.navbar_items.length - 2][0]}
                                        </p>
                                    </div>
                                </AnyAttribute>
                            </div>
                        </div>
                    </div>

                    {/* language selector */}
                    <LanguageSelector 
                        navbar_or_hamburguer_menu = {"navbar"} 
                        navbar_style = {navbar_style} 
                        navbar_background_color = {navbar_background_color}/>

                    {/* services modal */}
                    <div
                        ref = {tokenModalRef}
                        className = {styles.services_modal_div}
                        onClick = {() => handle_click("SERVICES")}
                        onMouseEnter = {() => set_mouse_is_on_services_modal(true)}
                        onMouseLeave = {() => set_mouse_is_on_services_modal(false)}
                        style = {{
                            left: services_word_position["left"] + "px",
                            top: services_word_position["top"] + "px",
                            visibility: (show_services_word_modal ? "visible" : "hidden"),
                            opacity: (show_services_word_modal ? "1.0" : "0.0"),
                            transform: (!show_services_word_modal ? "scale(1.02)" : "scale(1.0)")
                        }}>
                        <ServicesModal 
                            services_word_position = {services_word_position}
                            show_services_word_modal = {show_services_word_modal}/>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Navbar;