import React from 'react';
import { useState, useEffect } from 'react';
import styles from './styles/company.module.scss';
import json_company_en from './jsons/en/company_en.json';
import json_company_pt from './jsons/pt/company_pt.json';

const Company = () => {
    
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_company_en : json_company_pt;

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
        })
    });

    const handle_click = () => {
        window.scrollTo(0, 10000);
    }
    
    return (
        <div className = {styles.root_div}>

            <div className = {styles.call_to_action_div}>

                <div 
                    className = {styles.call_to_action_background_div}
                    style = {{
                        backgroundImage: `url(${require(`${json_data.call_to_action_section.background_image}`)})`
                    }}/>
                
                <div className = {styles.call_to_action_inner_div}>
                    <div 
                        className = {styles.call_to_action_text_div}
                        dangerouslySetInnerHTML = {{__html: json_data.call_to_action_section.text}}
                        style = {{
                            fontSize: "30px"
                        }} />
                </div>
                
            </div>

            <div className = {styles.five_pillars_div}>
                
                <div 
                    className = {styles.five_pillars_background_div}
                    style = {{
                        backgroundImage: `url(${require(`${json_data.five_pillars_section.background_image}`)})`
                    }}/>

                <div className = {styles.five_pillars_inner_div}>

                    <div 
                        className = {styles.five_pillars_left_text_div}
                        style = {{
                            fontSize: "28px"
                        }}>
                        {json_data.five_pillars_section.left_text}
                    </div>

                    <div className = {styles.five_pillars_items_div}>
                        {json_data.five_pillars_section.items.map((item, index) => (
                            <div
                                key = {index}
                                className = {styles.five_pillars_item_div}
                                style = {{
                                    width: (index === (json_data.five_pillars_section.items.length - 1) ? "100%" : "35%"),
                                    flex: (index === (json_data.five_pillars_section.items.length - 1) ? "" : "1"),
                                    flexBasis: (index === (json_data.five_pillars_section.items.length - 1) ? "100%" : ""),
                                }}>
                                    <div 
                                        className = {styles.five_pillars_item_title}
                                        style = {{
                                            textAlign: (index === (json_data.five_pillars_section.items.length - 1) ? "center" : "left"),
                                        }}>
                                        {item.title}
                                    </div>

                                    <div 
                                        className = {styles.five_pillars_subitems_div}
                                        style = {{
                                            display: (index === (json_data.five_pillars_section.items.length - 1) ? "none" : "flex"),
                                        }}>
                                        {item.items.map((sub_item, sub_index) => (
                                            <div 
                                                key = {sub_index}
                                                className = {styles.five_pillars_subitem_text}>
                                                {sub_item}
                                            </div>
                                        ))}
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className = {styles.company_description_div}>

                <div 
                    className = {styles.company_description_background_div}
                    style = {{
                        backgroundImage: `url(${require(`${json_data.company_description_section.background_image}`)})`
                    }}/>

                <div className = {styles.company_description_inner_div}>

                    <div
                        className = {styles.company_description_main_text} 
                        dangerouslySetInnerHTML = {{__html: json_data.company_description_section.main_text}}
                        style = {{
                            fontSize: "25px",
                            width: "75%",
                        }}/>

                    <div
                        className = {styles.company_description_secondary_text} 
                        dangerouslySetInnerHTML = {{__html: json_data.company_description_section.secondary_text}}
                        style = {{
                            fontSize: "20px",
                            width: "75%",
                        }}/>

                    <div className = {styles.services_items_div}>
                        {json_data.company_description_section.services_items.map((item, index) => (
                            <div
                                key = {index} 
                                className = {styles.services_item_div}>
                                
                                <div 
                                    className = {styles.services_item_image}
                                    style = {{
                                        backgroundImage: `url(${require(`${item.image}`)})`
                                    }}/>

                                <div className = {styles.services_item_fade} />
                                
                                <div className = {styles.services_item_content_div}>
                                    <div className = {styles.services_item_title}>
                                        {item.title}
                                    </div>

                                    <div className = {styles.services_item_description}>
                                        {item.description}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className = {styles.lets_talk_banner_div}>
                
                <div 
                    className = {styles.lets_talk_banner_background_div}
                    style = {{
                        backgroundImage: `url(${require(`${json_data.lets_talk_banner.background_image}`)})`
                    }}/>

                <div className = {styles.lets_talk_banner_inner_div}>
                    
                    <div 
                        className = {styles.lets_talk_banner_text} 
                        dangerouslySetInnerHTML = {{__html: json_data.lets_talk_banner.text}}
                        style = {{
                            fontSize: "25px",
                        }} />

                    <div
                        onClick = {() => handle_click()} 
                        className = {styles.lets_talk_button}>
                        <div className = {styles.lets_talk_button_text}>
                            {json_data.lets_talk_banner.button_text}
                        </div>

                        <div 
                            className = {styles.lets_talk_button_arrow} 
                            style = {{
                                backgroundImage: `url(${require(`${json_data.lets_talk_banner.arrow_image}`)})`
                            }}/>
                            
                    </div>

                </div>

            </div>
        
        </div>
    );
}

export default Company;