import React from 'react';
import { useState, useEffect } from 'react';
import styles from './styles/cover.module.scss';
import json_cover_en from './jsons/en/cover_en.json';
import json_cover_pt from './jsons/pt/cover_pt.json';

const Cover = () => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const [language, set_language] = useState(localStorage.getItem("language"));
    const json_data = language === "EN" ? json_cover_en : json_cover_pt;
    const [window_width, set_window_width] = useState(window.innerWidth);

    // add event listeners
    useEffect(() => {

        // listen for language changes
        function language_change() {
            set_language(localStorage.getItem("language"));
        }
        window.addEventListener('language_change', language_change);

        // listen for window resizes
        function resize() { 
            set_window_width(window.innerWidth); 
        }
        window.addEventListener('resize', resize);

        // do some cleanup (i.e., remove the event listeners if this component ends up being unmounted)
        return(() => {
            window.removeEventListener('language_change', language_change);
            window.removeEventListener('resize', resize);
        })
    });

    return ( 
        <div className = {styles.root}>
            <div 
                className = {styles.root_background_div} 
                style = {{
                    backgroundImage: `url(${require(`${json_data.desktop_image}`)})`
                }}/>

            <div className = {styles.first_gradient_div} />

            <div className = {styles.second_gradient_div} />

            <div className = {styles.root_inner_div}>
                <div 
                    className = {styles.title_div}
                    style = {{
                        maxWidth: (window_width < 600 ? "100%" : "50%"),
                        marginLeft: (window_width < 600 ? "" : "40px")
                    }}>
                    <div 
                        className = {styles.title_background_div} 
                        style = {{
                            backgroundImage: `url(${require(`${json_data.title_background_image}`)})`
                        }}/>

                    <div className = {styles.title_inner_div}>
                        <div 
                            className = {styles.title_main_text_div}
                            style = {{
                                textAlign: (window_width < 600 ? "center" : "left"),
                                fontSize: (window_width < 835 ? (window_width < 500 ? "40px" : "40px") : "55px")
                            }}>
                            {json_data.main_text}
                        </div>

                        <div 
                            className = {styles.title_secondary_text_div}
                            style = {{
                                textAlign: (window_width < 600 ? "center" : "left"),
                                paddingLeft: (window_width < 600 ? "30px" : ""),
                                paddingRight: (window_width < 600 ? "30px" : ""),
                                fontSize: (window_width < 835 ? (window_width < 500 ? "20px" : "20px") : "25px")
                            }}
                            dangerouslySetInnerHTML = {{__html: json_data.secondary_text}} />
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Cover;